// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

// Icons
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
    font-family: 'Poppins', sans-serif;
}

hr {
    margin: 5em 8.5% 5em 8.5%;
    border: 0;
    border-top: 2px solid #919191;
}

.logo {
    //vertical-align: middle;
    max-width: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.logo-welcome {
    width: 70%;
}

.logo-welcome-mobile {
    width: 80%;
}

.logo-link:hover {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

.welcome-title {
    min-height: 200px;
}

// .services-title {
//     min-height: 100px;
// }

// .services2-title {
//     min-height: 100px;
// }

.footer-company {
    min-height: 70px;
}

.card {
    min-width: 350px;
}

.fadein {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.custom-icon:hover {
    text-decoration: none;
}

.custom-wrapper {
    margin-right: auto; /* 1 */
    margin-left: auto; /* 1 */

    //max-width: 960px; /* 2 */

    padding-right: 10px; /* 3 */
    padding-left: 10px; /* 3 */
}

.toggle-light-dark-mode:hover {
    cursor: pointer;
}

.pull-left {
    float: left !important;
}

.media-body, .media-left, .media-right {
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    zoom: 1;
    width: 10000px;
}

.padding-right-9rem {
    padding-right: 9rem !important;
}

.margin-bottom-2em {
    margin-bottom: 2em;
}

a {
    background-color: transparent;
    color: #000000;
    text-decoration: none;
}

.vanta-row {
    min-height: 600px;
}

.margin-login {
    margin-top: 8em;
    margin-bottom: 8em;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Image inner circle image */

.bulletpoint {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #808080;
    background-size: 60% 60% !important;
    margin-left: auto;
    margin-right: 0;
}

.bulletpoint-desc-padding {
    padding-right: 20%;
}

.bulletpoint-lexicon {
    background: #808080 url("/img/iconmonstr-idea-11.svg") center center no-repeat;
    // filter: invert(1);
}

.bulletpoint-encryption {
    background: #808080 url("/img/iconmonstr-safe-5.svg") center center no-repeat;
    // filter: invert(1);
}

.bulletpoint-streams {
    background: #808080 url("/img/iconmonstr-share-7.svg") center center no-repeat;
    // filter: invert(1);
}

.bulletpoints-desc {
    height: auto;
    min-height: 200px;
}

/* End Image inner circle image */

/* Videos */

.header-video {
    width: 81vw;
}

.body-video {
    width: 30vw;
}

.border-top-solid {
    border-top: #dedede 2px solid;
}

.font-size-smaller {
    font-size: smaller;
}

/* End Videos */

/* Tablets */
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

    /* CSS */
    .welcome-title {
        height: 450px !important;
    }

    .body-video {
        width: 80vw !important;
    }

    /*.display-none {
        display: none !important;
    }*/

    .bulletpoints-desc {
        height: auto;
        min-height: 300px;
    }

    /*.login-form {
        padding-top: 30%;
    }*/

    .bulletpoint-logo-align {
        padding-right: 33%;
        margin-bottom: 2em;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    /* CSS */
    .welcome-title {
        height: 450px !important;
    }

    .body-video {
        width: 80vw !important;
    }

    /* .display-none {
         display: none !important;
     }*/

    /*.login-form {
        padding-top: 23%;
    }*/
}

/* End Tablets */

/* Mobile */
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    .logo {
        max-width: 10%;
    }

    hr {
        margin: 2em 0 2em 0;
        border: 0;
        //border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 2px solid #919191;
    }

    .welcome-title {
        height: 400px !important;
    }

    .mb-center {
        text-align: center;
    }

    .header-video {
        width: 92vw !important;
    }

    .body-video {
        width: 92vw !important;
    }

    .display-none {
        display: none !important;
    }

    .display-sm-none {
        display: none;
    }

    .display-3 {
        font-size: 3.5rem !important;
    }

    // .services-title {
    //     min-height: 175px;
    // }

    // .services2-title {
    //     min-height: 185px;
    // }

    .footer-company {
        min-height: 160px;
    }

    .bulletpoint-logo-align {
        padding-right: 33%;
        margin-bottom: 2em;
    }

    .bulletpoint-desc-padding {
        padding-right: 0;
    }

    .bulletpoints-desc {
        height: auto;
        min-height: 250px;
    }

    /*.login-form {
        padding-top: 31%;
    }*/
}

.dark-mode-icon {
    margin-bottom: 3px;
}

.custom-shadow:focus {
    box-shadow: 0 0 0 0.2rem rgb(52 58 64 / 50%);
}

.subscribe-form {
    max-width: 700px;
}

/* End Mobile */

/* Manual Resize */

@media (min-width: 0px) and (max-width: 1360px) {

    .header-video {
        width: 93vw !important;
    }

    .display-none {
        display: none !important;
    }

    .display-sm-none {
        display: none;
    }
    .bulletpoint-logo-align {
        padding-right: 33%;
        margin-bottom: 2em;
    }

    /*.login-form {
        padding-top: 31%;
    }*/
}

/* End Manual Resize */

p {
    font-size: 1.5em;
}
